<template>
  <div class="cropper">
    <div class="cropper__overlay"></div>
    <div class="cropper__body">
      <vue-croppie
        :key="1"
        ref="croppieRef"
        :enableResize="false"
        :enableOrientation="true"
        :enableZoom="true"
        :showZoomer="false"
        :viewport="{width: 280, height: 280, type: 'square'}"
        :boundary="{ width: 280, height: 280 }"
      >
      </vue-croppie>
      <div class="cropper__buttons">
        <div class="cropper__save button button_pink mb-4" @click="crop">Сохранить</div>
        <div class="cropper__cancel button button_empty-pink" @click="closeCropper">Отмена</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cropper",
  props: {
    file: File
  },
  data: () => ({
    cropped: null
  }),
  methods: {
    croppie(e) {
      let file = e;
      if (!file) return;
      this.imageSelected = true;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };
      reader.readAsDataURL(file);
    },
    crop() {
      let options = {
        type: "blob",
        format: "jpeg" | "png",
        size: { width: 200, height: 200 },
      };
      this.$refs.croppieRef.result(options, (output) => {
        this.cropped = output;
        this.saveCropper()
      });
    },
    closeCropper() {
      this.$emit('closeCropper')
    },
    saveCropper() {
      this.$emit('saveCropped', this.cropped)
    }
  },
  mounted() {
    if(this.file) {
      this.croppie(this.file)
    }
    
  }
};
</script>

<style lang="scss" scoped>
.cropper {
  &__body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    background-color: #fff;
    padding: 30px;
    z-index: 999999;
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(31, 31, 31, 0.6);
    transition: opacity 0.3s ease;
    z-index: 9999;
  }
}
</style>

<style>
  /* .croppie-container .cr-boundary {
    height: 400px !important;
    width: 100%;
    background: #ffffff;
  }
  @media screen and (max-width: 767px) {
    .croppie-container .cr-boundary {
      height: 236px !important;
      width: 100%;
      background: #ffffff;
    }
  } */
</style>