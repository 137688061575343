<template>
  <transition-group name="modal-v">
    <div :key="0" class="modal-avatar">
      <div class="modal-avatar__container">
        <div class="modal-avatar__content">
          <div class="d-md-none">
            <div
              class="modal-avatar__back button button_small button_empty-pink"
              @click="closeModal"
            >
              <span class="mr-2">
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 1L1 6L6 11"
                    stroke="currentColor"
                    stroke-linecap="square"
                  />
                </svg>
              </span>
              Назад
            </div>
          </div>
          <div class="modal-avatar__title">Новый аватар</div>

          <div class="modal-avatar__close-btn" @click="closeModal">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 7L25 25"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
              />
              <path
                d="M25 7L7 25"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>
          </div>

          <div class="modal-avatar__body">
            <div class="modal-avatar__body-descr">
              Загрузите своё фото или выберите аватар из представленных шаблонов
            </div>

            <div class="body-avatar">
              <div class="body-avatar__container">
                <div class="avatar__custom-wrap">
                  <div
                    class="avatar avatar__custom"
                    :class="{
                      chosen: user.photo,
                    }"
                  >
                    <input type="file" @change="uploadAvatar($event)" />
                    <div class="avatar__custom-edit" v-if="user.photo">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="12" cy="12" r="12" fill="#830051" />
                        <path
                          d="M12 16H16.5"
                          stroke="white"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.25 7.75011C14.4489 7.5512 14.7187 7.43945 15 7.43945C15.1393 7.43945 15.2772 7.46689 15.4059 7.52019C15.5346 7.57349 15.6515 7.65162 15.75 7.75011C15.8485 7.8486 15.9266 7.96553 15.9799 8.09422C16.0332 8.2229 16.0607 8.36083 16.0607 8.50011C16.0607 8.6394 16.0332 8.77733 15.9799 8.90601C15.9266 9.0347 15.8485 9.15162 15.75 9.25011L9.5 15.5001L7.5 16.0001L8 14.0001L14.25 7.75011Z"
                          stroke="white"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <img
                      v-if="user.photo || avatarPreview"
                      :src="avatarPreview"
                    />
                    <svg
                      v-else
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.6673 25.3333C30.6673 26.0406 30.3864 26.7189 29.8863 27.219C29.3862 27.719 28.7079 28 28.0007 28H4.00065C3.29341 28 2.61513 27.719 2.11503 27.219C1.61494 26.7189 1.33398 26.0406 1.33398 25.3333V10.6667C1.33398 9.95942 1.61494 9.28115 2.11503 8.78105C2.61513 8.28095 3.29341 8 4.00065 8H9.33398L12.0007 4H20.0007L22.6673 8H28.0007C28.7079 8 29.3862 8.28095 29.8863 8.78105C30.3864 9.28115 30.6673 9.95942 30.6673 10.6667V25.3333Z"
                        stroke="#830051"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.9993 22.6667C18.9449 22.6667 21.3327 20.2789 21.3327 17.3333C21.3327 14.3878 18.9449 12 15.9993 12C13.0538 12 10.666 14.3878 10.666 17.3333C10.666 20.2789 13.0538 22.6667 15.9993 22.6667Z"
                        stroke="#830051"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div
                    class="avatar__delete"
                    @click="removeAvatar()"
                    v-if="user.photo"
                  >
                    Удалить
                  </div>
                </div>
                <div
                  class="avatar avatar__def"
                  :class="{
                    chosen:
                      chosenImg === 1 ||
                      (!user.photo &&
                        user.default_photo ===
                          '/content/cabinet/default-avatar.svg'),
                  }"
                  @click="
                    changeAvatar(
                      '/content/cabinet/default-avatar.svg',
                      1
                    )
                  "
                >
                  <img
                    :src="
                      '/content/cabinet/default-avatar.svg'
                    "
                  />
                </div>
                <div
                  class="avatar avatar__men"
                  :class="{
                    chosen:
                      chosenImg === 2 ||
                      (!user.photo &&
                        user.default_photo ===
                          '/content/cabinet/man-avatar.svg'),
                  }"
                  @click="
                    changeAvatar(
                      '/content/cabinet/man-avatar.svg',
                      2
                    )
                  "
                >
                  <img
                    :src="'/content/cabinet/man-avatar.svg'"
                  />
                </div>
                <div
                  class="avatar avatar__women"
                  :class="{
                    chosen:
                      chosenImg === 3 ||
                      (!user.photo &&
                        user.default_photo ===
                          '/content/cabinet/woman-avatar.svg'),
                  }"
                  @click="
                    changeAvatar(
                      '/content/cabinet/woman-avatar.svg',
                      3
                    )
                  "
                >
                  <img
                    :src="'/content/cabinet/woman-avatar.svg'"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal-avatar__save-btn d-md-inline-flex button button_pink"
            @click="saveAvatar"
          >
            Сохранить
          </div>
        </div>
      </div>
      <Cropper
        @closeCropper="closeCropper"
        @saveCropped="saveCropped"
        v-if="originalImage"
        :file="originalImage"
      />
    </div>
  </transition-group>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Cropper from "../../pageComponents/Cropper.vue";
export default {
  components: { Cropper },
  name: "ChangeAvatar",
  data: () => ({
    chosenImg: false,
    params: {},
    avatarPreview: null,
    originalImage: null,
    croppieImg: null,
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["deleteUserAvatar"]),
    closeModal() {
      this.$emit("close", false);
    },
    escCloseModal(e) {
      if (e.key === "Escape") {
        this.closeModal();
      }
    },
    removeAvatar() {
      if (this.deleteUserAvatar(this.user.id)) {
        this.avatarPreview = null;
      }
    },
    saveAvatar() {
      if (Object.keys(this.params).length) {
        if (!!this.params.photo && !!this.params.default_photo) return;
        if (this.params.default_photo) {
          if (this.deleteUserAvatar(this.user.id)) {
            this.avatarPreview = null;
            delete this.params.photo;
          }
        }
        this.$emit("changeAvatar", this.params);
      }
      this.$emit("close");
    },
    uploadAvatar(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.avatarPreview = URL.createObjectURL(files[0]);
      this.originalImage = files[0];
      this.params = { photo: files[0] };
      document.querySelector('input[type="file"]').value = "";
    },
    changeAvatar(path, id) {
      this.chosenImg = id;
      this.params = { default_photo: path };
    },
    closeCropper() {
      this.params = {};
      this.avatarPreview = null;
      this.originalImage = null;
      document.querySelector('input[type="file"]').value = "";
    },
    saveCropped(res) {
      this.originalImage = null;
      this.avatarPreview = URL.createObjectURL(res);
      this.params = { photo: res };
    },
  },
  mounted() {
    if (this.user.photo) {
      this.avatarPreview = this.$root.host + this.user.photo;
    }
    window.addEventListener("keydown", this.escCloseModal);
  },
  destroy() {
    window.removeEventListener("keydown", this.escCloseModal);
  },
};
</script>

<style lang="scss" scoped>
.modal-v-enter-active,
.modal-v-leave-active {
  transition: all 0.5s ease;
}
.modal-v-enter,
.modal-v-leave-to {
  opacity: 0;
  transform: translateY(0);
}

.modal-avatar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 31, 31, 0.6);
  transition: opacity 0.3s ease;
  z-index: 9999;
  &__container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
  }
  &__back {
    max-width: 87px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin: 278px auto 0;
    padding: 48px;
    color: #000;
    background-color: #fff;
    box-sizing: border-box;
    transform: translate(0, 0);
    transition: all 0.3s ease;
    @media screen and (max-width: 1220px) {
      max-width: 592px;
    }
    @media screen and (max-width: 767px) {
      height: 100%;
      margin: 0;
      justify-content: flex-start;
      padding: 32px 16px;
    }
  }
  &__title {
    margin-bottom: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    color: #1f1f1f;
    @media screen and (max-width: 767px) {
      margin-top: 32px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    color: #000;
    transition: 0.3s;
    &:hover {
      color: #830051;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__body {
    margin-bottom: 48px;
    &-descr {
      margin-bottom: 48px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #1f1f1f;
    }
    .body-avatar {
      .avatar {
        position: relative;
        cursor: pointer;
        width: 101px;
        height: 101px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f8f8f8;
        border-radius: 99999px;
        margin-right: 32px;
        &.chosen {
          border: 2px solid #830051;
        }
        &__delete {
          margin-top: 8px;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          text-decoration-line: underline;
          cursor: pointer;
          color: #830051;
        }
        &__custom {
          margin-right: 0;
          border: 2px dashed #830051;

          &-wrap {
            margin-right: 32px;
          }
          &-edit {
            position: absolute;
            top: 0;
            right: 0;
          }
          & img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
            object-position: center;
            border-radius: 50%;
          }

          & input {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1000;
            cursor: pointer;
            &::-webkit-file-upload-button {
              cursor: pointer;
            }
          }
        }
        &__women {
          margin-right: 0;
        }
        @media screen and (max-width: 767px) {
          margin: 24px;
        }
        @media screen and (max-width: 374px) {
          margin: 12px;
        }
      }
      &__container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        @media screen and (max-width: 767px) {
          justify-content: center;
          flex-wrap: wrap;
        }
      }
    }
  }
  &__save-btn {
    max-width: 117px;
    @media screen and (max-width: 767px) {
      max-width: none;
    }
  }
}
</style>